import { useRuntimeConfig } from '#app';
import httpCommon from '@/api/httpCommon/httpCommon';

/**
 * Asynchronously performs OCR (Optical Character Recognition) operation by sending a POST request.
 * The request is sent to an OCR service, specified by the runtime configuration.
 * @param {string|number} cargoIndex - The cargo index to be used in the OCR operation.
 * @returns {Promise<object>} A promise that resolves to the response data from the OCR service.
 */
const ocrGetAsync = async cargoIndex => {
    const runtimeConfig = useRuntimeConfig();
    const data = JSON.stringify({
        id: 1,
        jsonrpc: '2.0',
        method: 'OCR.Get',
        params: { cargoIndex },
        disableCache: true,
        apiExchangeProtocol: runtimeConfig.public.apiExchangeProtocol,
    });

    const config = {
        data: data,
        method: 'post',
        maxBodyLength: Infinity,
        url: runtimeConfig.public.apiUrl,
        headers: {
            ApplicationID: runtimeConfig.public.ApplicationId,
            'Content-Type': 'application/json',
        },
    };

    return (await httpCommon().request(config)).data;
};

export { ocrGetAsync };
